<template>

  <a-card :bordered="false" class="card-product header-solid mb-24">
    <template #title>
      <h6>跟单浪浪</h6>
    </template>

    <a-row type="flex" align="middle" style="justify-content: center;">
      <a-space direction="vertical" :size="16" style="width: 71%;">
        <a-alert
            message="跟单风险提示："
            description="该交易员数据为第三方提供的OKX交易所数据，当该交易员在OKX交易时，将通过交易所官方api对接分享仓位信息。及时性高，不受交易所官网5分钟延迟展示持仓信息限制。跟单猿工具并不能保证该第三数据的准确性和稳定性，如需跟单，请悉知跟单风险！"
            type="warning"
            show-icon
            closable
        />
      </a-space>
    </a-row>

    <div style="margin-top: 50px">
      <h6>
        <span style="color: #EC575BFF;margin-right: 4px">*</span>
        跟单设置
      </h6>
      <a-form
          :form="form"
          @submit="handleSubmit"
          :hideRequiredMark="true"
          style="width: 70%"
      >
        <a-row :gutter="[24]" type="flex" justify="start">
          <a-col :span="14">
            <a-form-item class="mb-10" label="选择你的跟单API" :colon="false">
              <a-select v-model="id" v-decorator="[
                      'id',
                      {
                        rules: [{ required: true, message: '选择你的跟单API' }]
                      }
                    ]">
                <a-select-option v-for="option in apiOptions" :key="option.id" :id="option.id">
                  {{ option.api_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="14">
            <a-form-item class="mb-10" label="跟单模式" :colon="false">
              <a-radio-group button-style="solid" v-model:value="follow_type">
<!--                <a-radio-button value="2">固定比例</a-radio-button>-->
                <a-radio-button value="3">智能跟单</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col v-if="follow_type == 3" :span="14">
            <a-form-item class="mb-10" label="投资额（将作为计算每次开单的金额基数）" :colon="false">
              <a-input placeholder=">100 注意：金额过低可能会无法开单" prefix="$" suffix="USDT" v-model="investment"
                       v-decorator="['investment',{ rules: [
                             { required: true, message: '请输入你的投资额！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 100) {
                                  callback('输入数值必须大于100');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
            </a-form-item>
            <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【智能跟单说明】
              </span>
            <br/>
            <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    将按照投资金额的大小，按比例智能跟单，从而保证仓位大小一致。
              </span>
            <br/>
            <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    仓位大小 = 当前仓位价值 / 交易账户权益
            </span>

          </a-col>
<!--          <a-col :span="14">-->
<!--            <a-form-item class="mb-10" label="仓位模式" :colon="false">-->
<!--              <a-radio-group button-style="solid">-->
<!--                <a-radio-button value="1">全仓</a-radio-button>-->
<!--                <a-radio-button value="2">逐仓</a-radio-button>-->
<!--              </a-radio-group>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :span="14">-->
<!--            <a-form-item class="mb-10" label="杠杆设置" :colon="false">-->
<!--              <a-radio-group button-style="solid" style="margin-right: 10px" v-model:value="lever_set">-->
<!--                <a-radio-button value="1">跟随交易员</a-radio-button>-->
<!--                <a-radio-button value="2">自定义杠杆</a-radio-button>-->
<!--              </a-radio-group>-->
<!--              <a-input placeholder="1至20之间任意整数" style="width: 248px"-->
<!--                       v-decorator="['leverage',{ rules: [-->
<!--                             { validator: (rule, value, callback) => {-->
<!--                                const numberValue = parseFloat(value);-->
<!--                                if (isNaN(numberValue) || !isFinite(numberValue)) {-->
<!--                                  callback('请输入有效的数字！');-->
<!--                                } else if (numberValue < 1) {-->
<!--                                  callback('输入数值必须大于1');-->
<!--                                } else if (numberValue > 20) {-->
<!--                                  callback('输入数值必须小于20');-->
<!--                                } else if (!/^\d+(\.\d+)?$/.test(value)) {-->
<!--                                  callback('请输入标准的数字格式，不要包含非数字字符');-->
<!--                                }else {-->
<!--                                  callback();-->
<!--                                }-->
<!--                              },-->
<!--                              trigger: 'change'-->
<!--                            },-->
<!--                          ]-->
<!--                         },]"/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :span="14">
            <a-form-item class="mb-10" label="开仓模式" :colon="false">
              <a-radio-group button-style="solid" v-model:value="first_open_type">
                <a-radio-button :value="1">当前市价</a-radio-button>
<!--                <a-radio-button :value="2">区间委托</a-radio-button>-->
              </a-radio-group>
            </a-form-item>
          </a-col>
<!--          <a-col :span="14">-->
<!--            <a-form-item class="mb-10" label="交易员收益区间" :colon="false">-->
<!--              <a-input placeholder="5（交易员收益率小于5%时开仓），-5（亏损大于5%时开仓）" prefix=" < " suffix="%"-->
<!--                       v-decorator="['uplRatio',{ rules: [-->
<!--                     { required: true, message: '请输入你的止盈百分比！' },-->
<!--                     { validator: (rule, value, callback) => {-->
<!--                        const numberValue = parseFloat(value);-->
<!--                        if (isNaN(numberValue) || !isFinite(numberValue)) {-->
<!--                          callback('请输入有效的数字！');-->
<!--                        } else if (!/^-?\d+(\.\d+)?$/.test(value)) {-->
<!--                          callback('请输入标准的数字格式，不要包含非数字字符');-->
<!--                        } else {-->
<!--                          callback();-->
<!--                        }-->
<!--                      },-->
<!--                      trigger: 'change'-->
<!--                    },-->
<!--                  ]-->
<!--                 },]"/>-->
<!--            </a-form-item>-->
<!--            <span class="text-primary" style="font-weight: 400; font-size: 12px">-->
<!--                    【开仓模式说明】-->
<!--              </span>-->
<!--            <br />-->
<!--            <span class="text-primary" style="font-weight: 400; font-size: 12px">-->
<!--                      区间限价开仓只在交易员新开仓时生效，加仓时不生效。该模式在其他交易所也可使用。-->
<!--              </span>-->
<!--            <br />-->
<!--            <span class="text-primary" style="font-weight: 400; font-size: 12px">-->
<!--                      系统会自动监控最合适的进场点位第一时间开仓。-->
<!--              </span>-->
<!--          </a-col>-->
          <a-col :span="14">
            <a-form-item class="mb-10" label="首单跟单设置" :colon="false">
              <a-radio-group button-style="solid" v-model="first_order_set">
                <a-radio-button value="1">有新的加仓，立即跟单</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <!-- 更多高级配置 -->
<!--          <a-col :span="14" style="margin-top: 10px">-->
<!--            <a class="text-dark" @click="toggleShowLess('wechat')" style="font-weight: 600">-->
<!--              高级设置-->
<!--              <a-icon :type="showLess ? 'up' : 'down'" class="ml-5" />-->
<!--            </a>-->
<!--          </a-col>-->

          <a-col :span="14" v-if="showLess">
            <a-row type="flex" align="middle" style="margin-top: 20px;">
              <a-col :span="24" :md="12" class="col-info">
                <div class="avatar-info">
                  <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">🔁 反向跟单 ( 娱乐功能 )</p>
                </div>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <small class="mr-5" v-if="posSide_set_visible === false">关闭</small>
                <small class="mr-5" v-if="posSide_set_visible === true">开启</small>
                <a-switch default-checked v-model="posSide_set_visible" @change="onChangeMode"/>
              </a-col>
            </a-row>

            <a-row type="flex" align="middle" style="margin-top: 20px;">
              <a-col :span="24" :md="12" class="col-info">
                <div class="avatar-info">
                  <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">🚀 极速跟单 ( 限时免费 )</p>
                </div>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <small class="mr-5" v-if="fast_mode_visible === false">关闭</small>
                <small class="mr-5" v-if="fast_mode_visible === true">开启</small>
                <a-switch default-checked v-model="fast_mode_visible" @change="onChangeMode"/>
              </a-col>
            </a-row>


            <a-row type="flex" align="middle" style="margin-top: 20px;">
              <a-col :span="24" :md="12" class="col-info">
                <div class="avatar-info">
                  <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚠️ 交易止盈止损</p>
                </div>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <small class="mr-5" v-if="trade_trigger_visible === false">关闭</small>
                <small class="mr-5" v-if="trade_trigger_visible === true">开启</small>
                <a-switch default-checked v-model="trade_trigger_visible" @change="onChangeMode"/>
              </a-col>
            </a-row>
            <a-form-item class="mb-10" label="止盈百分比" :colon="false" v-if="trade_trigger_visible">
              <a-input placeholder="eg. 20（每笔交易盈利20%时止盈），0为不设置止盈" suffix="%"
                       v-model="tp_trigger_px"
                       v-decorator="['tp_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止盈百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
            </a-form-item>
            <a-form-item class="mb-10" label="止损百分比" :colon="false" v-if="trade_trigger_visible">
              <a-input placeholder="eg. 20（每笔交易亏损20%时止损），0为不设置止损" suffix="%"
                       v-model="sl_trigger_px"
                       v-decorator="['sl_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止损百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
            </a-form-item>

            <a-row type="flex" align="middle" style="margin-top: 20px;">
              <a-col :span="24" :md="12" class="col-info">
                <div class="avatar-info">
                  <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚠️ 任务止盈止损</p>
                </div>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <small class="mr-5" v-if="task_trigger_visible === false">功能开发中</small>
                <small class="mr-5" v-if="task_trigger_visible === true">开启</small>
                <a-switch disabled @change="onChangeMode"/>
              </a-col>
            </a-row>


            <a-row type="flex" align="middle" style="margin-top: 20px;">
              <a-col :span="24" :md="12" class="col-info">
                <div class="avatar-info">
                  <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">💹 24h成交量排行榜跟单</p>
                </div>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <small class="mr-5" v-if="vol24h_visible === false">功能开发中</small>
                <small class="mr-5" v-if="vol24h_visible === true">开启</small>
                <a-switch v-model="vol24h_visible" @change="onChangeMode"/>
              </a-col>
              <a-col :span="14" v-if="vol24h_visible">
                <a-form-item class="mb-10" label="榜单排名" :colon="false">
                  <a-input placeholder="eg. 20（只跟排行榜前20的币种）"
                           v-model="vol24h_num"
                           v-decorator="['vol24h_num',{ rules: [
                               { required: true, message: '排行榜前几？' },
                               { validator: (rule, value, callback) => {
                                  // 检查是否是正整数并在 0 到 50 之间
                                  const isPositiveInteger = /^\d+$/.test(value);
                                  const numberValue = parseInt(value, 10);
                                  if (!isPositiveInteger || numberValue <= 0) {
                                    callback('请输入大于 0 的整数！');
                                  } else if (numberValue >= 50) {
                                    callback('请输入小于 50 的整数！');
                                  } else {
                                    callback();
                                  }
                                },
                                trigger: 'change'
                              },
                            ]
                           },]"/>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row type="flex" align="middle" style="margin-top: 20px;">
              <a-col :span="24" :md="12" class="col-info">
                <div class="avatar-info">
                  <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚪️ 跟单币种白名单</p>
                </div>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <small class="mr-5" v-if="white_list_visible === false">功能开发中</small>
                <small class="mr-5" v-if="white_list_visible === true">开启</small>
                <a-switch v-model="white_list_visible" @change="onChangeMode"/>
              </a-col>
              <a-col :span="14" v-if="white_list_visible">
                <a-form-item class="tags-field mb-10" label="输入允许跟单的币种" :colon="false">
                  <a-select mode="tags" v-model="white_list" style="width: 170%" placeholder="eg. BTC-USDT（跟BTC-USDT-SWAP）" @change="handleWhiteTagChange">
                    <a-select-option v-for="tag in white_tags" :key="tag" :value="tag" selected>
                      {{ tag }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>


            <a-row type="flex" align="middle" style="margin-top: 20px;">
              <a-col :span="24" :md="12" class="col-info">
                <div class="avatar-info">
                  <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚫️ 跟单币种黑名单</p>
                </div>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <small class="mr-5" v-if="black_list_visible === false">功能开发中</small>
                <small class="mr-5" v-if="black_list_visible === true">开启</small>
                <a-switch v-model="black_list_visible" @change="onChangeMode"/>
              </a-col>
              <a-col :span="14" v-if="black_list_visible">
                <a-form-item class="tags-field mb-10" label="输入禁止跟单的币种" :colon="false">
                  <a-select mode="tags" v-model="black_list" style="width: 170%" placeholder="eg. BTC-USDT（不跟BTC-USDT-SWAP）" @change="handleBlackTagChange">
                    <a-select-option v-for="tag in black_tags" :key="tag" :value="tag" selected>
                      {{ tag }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <!-- 更多高级配置 -->
          <a-col :span="12" style="margin-top: 20px;">
            <a-button type="primary" class="px-25" @click="submit()" style="width: 100%">立即跟单</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-card>


</template>

<script>

import http from "@/utils/http";


export default {
  components: {},
  data() {
    return {
      apiOptions: [], // 用于存储选项数据
      id: null, // 跟单 API ID

      trader_platform: 6, // 交易员所在平,
      uniqueName: 'Bit浪浪', // 交易员名称
      role_type: '1', // ws跟单无需角色
      follow_type: '3',
      sums: '0', // 单笔跟单金额
      ratio: '0', // 固定比例跟单
      investment: '0', // 智能跟单 投资额（跟单本金）
      lever_set: '1', // 杠杆设置
      leverage: '1', // 杠杆倍数
      reduce_ratio: '0', //减仓比例
      first_open_type: 1, //开仓模式
      uplRatio: '0', //交易员当前收益率
      first_order_set: '1', // 首单跟单设置
      posSide_set: '1',
      fast_mode: '1',
    }
  },
  created() {

  },
  methods: {
    async fetchApiOptions() {
      try {
        const response = await http.get('/api/apiadd/?token=' + localStorage.getItem("token"));
        console.log("API Response:", response.data);
        if (response.data && Array.isArray(response.data.data)) {
          this.user = response.data.data[0].user;
          this.apiOptions = response.data.data.filter(item => item.status === 1).map(item => ({
            api_name: item.api_name,
            id: item.id
          }));
        } else {
          console.error("预期的数据格式不正确");
        }
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },
    async submit() {
      try {
        const data = {
          trader_platform: this.trader_platform,
          uniqueName: this.uniqueName,
          api: this.id,
          follow_type: this.follow_type,
          sums: this.sums,
          ratio: this.ratio,
          lever_set: this.lever_set,
          leverage: this.leverage,
          first_open_type: this.first_open_type,
          uplRatio: this.uplRatio,
          first_order_set: this.first_order_set,
          posSide_set: this.posSide_set,
          user: this.user,
          role_type: this.role_type,
          reduce_ratio: this.reduce_ratio,
          fast_mode: this.fast_mode,
          investment: this.investment,
          // trade_trigger_mode: this.trade_trigger_mode,
          // sl_trigger_px: this.sl_trigger_px,
          // tp_trigger_px: this.tp_trigger_px,
        }

        const response = await http.post('/api/taskadd/?token=' + localStorage.getItem("token"), data);
        if (response.data && response.data.code === 0) {
          this.$message.success("创建成功");
          this.$router.push({ path: '/tasklist' });

          try {
            await http.patch('/api/apiadd/' + this.stepTwoFormData.id + '/?token=' + localStorage.getItem("token"), {"status": 2,});
          } catch (patchError) {
            console.error("更新状态时发生错误:", patchError);
            // 处理 http.patch 请求的错误
          }
        } else if(response.data && response.data.code === 1001) {
          this.$message.error(response.data.error);
        }else {
          this.$message.error("创建失败");
        }
      }catch (error) {
        console.error("提交时发生错误:", error);
        // 处理错误
      }
    }
  },
  mounted() {
    this.fetchApiOptions();
  }
}
</script>

<style scoped>


</style>